import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  /* eslint-disable-next-line */
  baseURL: process.env.VUE_APP_NODE_ENV === 'staging' ? process.env.VUE_APP_API_STAGING : process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_API_PRODUCTION : process.env.VUE_APP_API_DEVELOP,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
